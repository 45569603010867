import { notNull } from "@warrenio/utils/notNull";
import { WModalContent } from "../../components/modal/WModal.tsx";
import { useStandardMutation } from "../../modules/api/useStandardMutation.ts";
import { deleteFloatingIpMutation } from "./floatingIpsQuery.ts";
import type { GQIpAddressItem } from "./FloatingIpsTable.tsx";

interface DeleteFloatingIpModalProps {
    item: GQIpAddressItem;
}

export function DeleteFloatingIpModalContent({ item }: DeleteFloatingIpModalProps) {
    const deleteMutation = useStandardMutation(deleteFloatingIpMutation);
    const { address, _location } = item;

    async function onSubmit() {
        await deleteMutation.mutateAsync({
            address: address,
            location: notNull(_location),
        });
    }

    return (
        <WModalContent title="Delete Floating IP" label="Delete" autoFocus modalAction={async () => await onSubmit()}>
            <p>
                Deleting Floating IP "<b>{address}</b>" will remove this IP from current user.
            </p>
        </WModalContent>
    );
}
