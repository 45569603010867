import { notNull } from "@warrenio/utils/notNull";
import { showModal } from "../../components/modal/registerModal.tsx";
import type { ActionItem } from "../ActionItem.tsx";
import { type UnassignFloatingIpModalProps, UnassignFloatingIpModalContent } from "./UnassignFloatingIpBlock.tsx";

export function unassignAction(item: UnassignFloatingIpModalProps | null): ActionItem {
    return {
        id: "unassign_ip",
        title: "Unassign IP",
        icon: "jp-wrong-icon",
        isDisabled: item == null,
        action: () => showModal(<UnassignFloatingIpModalContent {...notNull(item)} />),
    };
}
