import { adminMutation } from "../../utils/query/adminMutation.ts";

//#region Mutations

export const unassignFloatingIpMutation = adminMutation((apiClient) => ({
    entity: "floating ip",
    operation: "unassign",
    requestFn: async ({ location, address }: { location: string; address: string }) =>
        await apiClient.POST("/{location}/network/admin/ip_addresses/{address}/unassign", {
            params: { path: { location, address } },
        }),
}));

export const deleteFloatingIpMutation = adminMutation((apiClient) => ({
    entity: "floating ip",
    operation: "delete",
    requestFn: async ({ location, address }: { location: string; address: string }) =>
        await apiClient.DELETE("/{location}/network/admin/ip_addresses/{address}", {
            params: { path: { location, address } },
        }),
}));

//#endregion Mutations
