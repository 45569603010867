import { ActionMenu, type ActionMenuItemProps } from "../components/ActionMenu.tsx";
import { WToolButton, type WToolButtonProps } from "../components/button/WToolButton.tsx";

/** A universal action that can be shown as eg. a toolbar button or a menu item */
export interface ActionItem {
    id: string;
    title: string;
    icon?: string;
    isDisabled?: boolean;
    // TODO: Support links here (need to be supported in `ActionMenu` first)
    action: ActionMenuItemProps["action"];
}

const MAX_IMMEDIATE_ACTIONS = 2;

/** Renders a list of actions as {@link WToolButton}s */
export function ActionButtons({
    actions,
    maxImmediate = MAX_IMMEDIATE_ACTIONS,
    ...props
}: { actions: ActionItem[]; maxImmediate?: number } & Omit<
    WToolButtonProps,
    "action" | "label" | "icon" | "isDisabled"
>) {
    if (props.inTable && actions.length > maxImmediate) {
        return (
            <ActionMenu
                items={actions.map(
                    ({ id, title, icon, isDisabled, action }): ActionMenuItemProps => ({
                        id,
                        title,
                        icon: icon
                            ? `${icon} ${isDisabled ? "color-muted" : "color-primary"} size-0.875rem`
                            : undefined,
                        isDisabled,
                        action,
                    }),
                )}
            >
                <WToolButton {...props} label="Actions" icon="i-lucide:ellipsis-vertical" action={undefined} />
            </ActionMenu>
        );
    }

    return (
        <div className="HStack gap-1">
            {actions.map(({ id, title, icon, isDisabled, action }) => (
                <WToolButton key={id} {...props} icon={icon} label={title} isDisabled={isDisabled} action={action} />
            ))}
        </div>
    );
}
