import { WModalContent } from "../../components/modal/WModal.tsx";
import { useStandardMutation } from "../../modules/api/useStandardMutation.ts";
import { unassignFloatingIpMutation } from "./floatingIpsQuery.ts";

export interface UnassignFloatingIpModalProps {
    address: string;
    location: string;
}

export function UnassignFloatingIpModalContent({ address, location }: UnassignFloatingIpModalProps) {
    const unassignMutation = useStandardMutation(unassignFloatingIpMutation);

    async function onSubmit() {
        await unassignMutation.mutateAsync({ address, location });
    }

    return (
        <WModalContent
            title="Unassign Public IPv4 Address"
            label="Unassign"
            autoFocus
            modalAction={async () => await onSubmit()}
        >
            <p>
                Removing the public IPv4 address assignment from this resource will make the resource inaccessible from
                the public Internet. The IP address will remain reserved to the user's account.
            </p>
        </WModalContent>
    );
}
